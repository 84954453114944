import React from 'react';
import styles from './Header.module.scss';
import hitman from '../../../assets/images/man.png';
import ColdWar from '../../../assets/images/cold-war.png';
import MW3 from '../../../assets/images/mw3.png';

import MW from '../../../assets/images/modernw.png';
import Warzone2 from '../../../assets/images/warzone.png';
import Numbers from '../../features/Numbers/Numbers';

import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Fade';

const Header = () => (
  <header id='top' className={styles.root}>
    <div className={styles.text}>
      <Fade top delay={1000}>
        <h1 className={styles.maintext}>
          HITMAN <span>SERVICES</span>
        </h1>
      </Fade>
      <h6>
        Hitman Services is the one stop shop for{' '}
        <b>all your Call of Duty needs</b>, with the world's only Cold War
        Advanced UAV Tool, Warzone 3 and Modern Warfare 3 Cheats/Hacks, Cod
        Unlock All Tool, Aimbot, Bot lobby Tool, Spoofer, Cod Blocker, HWID
        change, Wall hacks ESP even Fortnite cheats. Brand new fresh<br></br>{' '}
        Blizzard Accounts,
        <b> there really is only one place you need to go to!</b>
        <br></br> Hitman Services!
      </h6>
      <Numbers />
      <h3>We offer cheats for</h3>

      <div className={styles.box_row}>
        <Slide left delay={500}>
          <div className={styles.box}>
            <img src={MW3} alt='Warzone 2 Logo' />
          </div>
        </Slide>
        <Slide left delay={1000}>
          <div className={styles.box}>
            <img src={MW} alt='Modern Warfare Logo' />
          </div>
        </Slide>
        <Slide left delay={1500}>
          <div className={styles.box}>
            <img src={ColdWar} alt='Cold War Logo' />
          </div>
        </Slide>
      </div>
      <p>...and much more!</p>
    </div>

    <div className={styles.image}>
      <Slide right delay={200}>
        <img src={hitman} alt='Hitman' />
      </Slide>
    </div>
  </header>
);

export default Header;
