import { faL } from "@fortawesome/free-solid-svg-icons";

const WARZONE = "Warzone 2";
const COLDWAR = "Cold War";
const MODERNWARFARE = "MW";
const MODERNWARFARE2 = "MW2";
const MODERNWARFARE3 = "MW3";
const VANGUARD = "Vanguard";
const BLACKOPS4 = "Black Ops 4";
const FORTNITE = "Fortnite";
const OTHER = "Other";

const data = {
  products: [
    {
      _id: "1",
      name: "Advanced UAV Tool",
      product_image: "images/products/product_1.png",
      status_1: "active",
      status_2: "undetected",
      category: [{ name: COLDWAR }],
      starting_price: "9.99",
      images: [
        {
          link: "https://i.imgur.com/Iojv7B7.gif",
        },
        {
          link: "https://i.imgur.com/wB6yy4s.gif",
        },
      ],
      subscriptions: [
        {
          price: "20",
          time: "Lifetime",
          shoppy_link:
            "https://hitmansservices.mysellix.io/product/62b10103a272b",
        },
        {
          price: "9.99",
          time: "1 Month",
          shoppy_link:
            "https://hitmansservices.mysellix.io/product/62b100b19249f",
        },
      ],
    },
    // {
    //   _id: '3',
    //   name: 'Unlock Tool',
    //   product_image: 'images/products/product_3.png',
    //   bestseller: false,
    //   status_1: 'active',
    //   status_2: 'undetected',
    //   category: [{ name: MODERNWARFARE2 }, { name: WARZONE }],
    //   starting_price: '20.00',
    //   images: [
    //     {
    //       link: 'https://i.imgur.com/7j9vlIv.gif',
    //     },
    //     { link: 'https://i.imgur.com/Ky1bEmr.gif' },
    //     {
    //       link: 'https://i.imgur.com/b9UNKMw.png',
    //     },
    //   ],
    //   subscriptions: [
    //     {
    //       price: '20',
    //       time: '1 Month',
    //       shoppy_link:
    //         'https://hitmansservices.sellix.io/product/6389fba80c80e',
    //     },
    //     {
    //       price: '40',
    //       time: 'Lifetime',
    //       shoppy_link:
    //         'https://hitmansservices.sellix.io/product/62b0fedc1eac9',
    //     },
    //   ],
    // },
    // {
    //   _id: '4',
    //   name: 'Vanguard Unlock Tool',
    //   // bestseller: true,
    //   product_image: 'images/products/product_19.png',
    //   status_1: 'active',
    //   status_2: 'undetected',
    //   category: [{ name: VANGUARD }],
    //   starting_price: '25.00',
    //   images: [
    //     {
    //       link: 'https://i.imgur.com/rUvJ8WM.gif',
    //     },
    //     {
    //       link: 'https://i.imgur.com/f06jsQn.gif',
    //     },
    //   ],
    //   subscriptions: [
    //     {
    //       price: '25',
    //       time: '1 Month',
    //       shoppy_link:
    //         'https://hitmansservices.sellix.io/product/62b0fb6c5a170',
    //     },
    //     {
    //       price: '60',
    //       time: 'Lifetime',
    //       shoppy_link:
    //         'https://hitmansservices.sellix.io/product/62b0fb181ad4f',
    //     },
    //   ],
    // },
    {
      _id: "5",
      name: "Shadow Ban Fix - Hyper Spoofer",
      product_image: "images/products/product_8.png",
      status_1: "active",
      status_2: "undetected",
      category: [
        { name: COLDWAR },
        { name: MODERNWARFARE2 },
        { name: WARZONE },
      ],
      starting_price: "25.00",
      images: [
        {
          link: "https://i.imgur.com/Zy0pZje.gif",
        },
        {
          link: "https://i.imgur.com/md6S7mn.gif",
        },
        {
          link: "https://i.imgur.com/aRW36TA.gif",
        },
      ],
      subscriptions: [
        {
          price: "25",
          time: "1 Month",
          shoppy_link:
            "https://hitmansservices.mysellix.io/product/62c483f4bd5c5",
        },
      ],
    },
    // {
    //   _id: '10',
    //   name: 'Warzone 2 Cheats',
    //   bestseller: false,
    //   product_image: 'images/products/product_12.png',
    //   status_1: 'active',
    //   status_2: 'undetected',
    //   category: [{ name: WARZONE }, { name: MODERNWARFARE2 }],
    //   starting_price: '20.00',
    //   images: [
    //     {
    //       link: 'https://i.imgur.com/MhMk4XT.gif',
    //     },
    //     {
    //       link: 'https://i.imgur.com/1y4EHBq.gif',
    //     },
    //     {
    //       link: 'https://i.imgur.com/QfT3yv8.gif',
    //     },
    //   ],
    //   subscriptions: [
    //     {
    //       price: '20',
    //       time: '1 Month',
    //       shoppy_link:
    //         'https://hitmansservices.sellix.io/product/6389fe0b1f8ea',
    //     },
    //     {
    //       price: '99',
    //       time: 'Lifetime',
    //       shoppy_link:
    //         'https://hitmansservices.sellix.io/product/6389ff120e9eb',
    //     },
    //   ],
    // },
    // {
    //   _id: '11',
    //   name: 'Perk Swapper',
    //   product_image: 'images/products/product_13.png',
    //   status_1: 'active',
    //   status_2: 'undetected',
    //   category: [{ name: MODERNWARFARE }],
    //   starting_price: '20.00',
    //   images: [
    //     {
    //       link: 'https://i.imgur.com/DKPdfcq.gif',
    //     },
    //   ],
    //   subscriptions: [
    //     {
    //       price: '20',
    //       time: 'Lifetime',
    //       shoppy_link:
    //         'https://hitmansservices.sellix.io/product/62b0fda6464ad',
    //     },
    //   ],
    // },
    // {
    //   _id: '13',
    //   name: 'Soft Unlock Camo Tool (Swapper)',
    //   product_image: 'images/products/product_15.png',
    //   status_1: 'active',
    //   status_2: 'undetected',
    //   category: [{ name: VANGUARD }],
    //   starting_price: '30.00',
    //   images: [
    //     {
    //       link: 'https://i.imgur.com/nRch0sw.gif',
    //     },
    //     {
    //       link: 'https://i.imgur.com/8hGnUG3.gif',
    //     },
    //   ],
    //   subscriptions: [
    //     {
    //       price: '30',
    //       time: 'Lifetime',
    //       shoppy_link:
    //         'https://hitmansservices.sellix.io/product/62b0fbfcaca2c',
    //     },
    //   ],
    // },
    // {
    //   _id: '18',
    //   name: 'Black Ops 4 Uav Tool',
    //   product_image: 'images/products/product_20.png',
    //   status_1: 'active',
    //   status_2: 'undetected',
    //   category: [{ name: BLACKOPS4 }],
    //   starting_price: '20.00',
    //   images: [
    //     {
    //       link: 'https://i.imgur.com/z6Ub6wI.gif',
    //     },
    //     {
    //       link: 'https://i.imgur.com/lg8lFZu.gif',
    //     },
    //   ],
    //   subscriptions: [
    //     {
    //       price: '20',
    //       time: 'Lifetime',
    //       shoppy_link:
    //         'https://hitmansservices.sellix.io/product/62b0fa734a4cb',
    //     },
    //   ],
    // },
    // {
    //   _id: "19",
    //   name: "Warzone 2 Premium",
    //   bestseller: false,
    //   product_image: "images/products/product_21.png",
    //   status_1: "active",
    //   status_2: "undetected",
    //   category: [{ name: WARZONE }],
    //   starting_price: "19.99",
    //   images: [
    //     {
    //       link: "https://i.imgur.com/54wGdGJ.gif",
    //     },
    //     {
    //       link: "https://i.imgur.com/4oW9jaC.gif",
    //     },
    //   ],
    //   subscriptions: [
    //     {
    //       price: "19.99",
    //       time: "1 Month",
    //       shoppy_link: "https://hitmansservices.sellix.io/product/62b0fa1fe69f7",
    //     },
    //     {
    //       price: "100",
    //       time: "Lifetime",
    //       shoppy_link: "https://hitmansservices.sellix.io/product/62b0f9d22d763",
    //     },
    //   ],
    // },
    // {
    //   _id: '21',
    //   name: 'Reseller 1 Tool Panel',
    //   product_image: 'images/products/product_23.png',
    //   status_1: 'start your own business',
    //   status_2: 'Ready to sell in 30 minutes',
    //   category: [
    //     { name: MODERNWARFARE },
    //     { name: COLDWAR },
    //     { name: VANGUARD },
    //     { name: BLACKOPS4 },
    //   ],
    //   starting_price: '500.00',
    //   images: [
    //     {
    //       link: 'https://i.imgur.com/AQ5sj87.gif',
    //     },
    //     {
    //       link: 'https://i.imgur.com/9FikWXs.gif',
    //     },
    //     {
    //       link: 'https://i.imgur.com/CEBP9q0.gif',
    //     },
    //   ],
    //   subscriptions: [
    //     {
    //       price: '500',
    //       time: '1 Tool Panel',
    //       shoppy_link:
    //         'https://hitmansservices.sellix.io/product/62b0f922ebb9e',
    //     },
    //   ],
    // },
    // {
    //   _id: '22',
    //   name: 'Instant Dark Aether Tool',
    //   product_image: 'images/products/product_24.png',
    //   status_1: 'active',
    //   status_2: 'undetected',
    //   category: [{ name: COLDWAR }],
    //   starting_price: '15.00',
    //   images: [
    //     {
    //       link: 'https://i.imgur.com/quSn009.gif',
    //     },
    //     {
    //       link: 'https://i.imgur.com/xdxBy6s.gif',
    //     },
    //   ],
    //   subscriptions: [
    //     {
    //       price: '15',
    //       time: '1 Day',
    //       shoppy_link:
    //         'https://hitmansservices.sellix.io/product/62b101fb9cdd9',
    //     },
    //     {
    //       price: '40',
    //       time: '1 Month',
    //       shoppy_link:
    //         'https://hitmansservices.sellix.io/product/62b101b0249c9',
    //     },
    //     {
    //       price: '100',
    //       time: 'Lifetime',
    //       shoppy_link:
    //         'https://hitmansservices.sellix.io/product/62b1015e18939',
    //     },
    //   ],
    // },
    // {
    //   _id: '23',
    //   name: "Pack a Punch Camo's Tool",
    //   product_image: 'images/products/product_25.png',
    //   status_1: 'active',
    //   status_2: 'undetected',
    //   category: [{ name: COLDWAR }],
    //   starting_price: '30.00',
    //   images: [
    //     {
    //       link: 'https://i.imgur.com/Gjkjudd.gif',
    //     },
    //     {
    //       link: 'https://i.imgur.com/babuzNh.gif',
    //     },
    //     {
    //       link: 'https://i.imgur.com/JR063Iv.gif',
    //     },
    //     {
    //       link: 'https://i.imgur.com/yYWX52C.gif',
    //     },
    //     {
    //       link: 'https://i.imgur.com/KNLC0r2.gif',
    //     },
    //   ],
    //   subscriptions: [
    //     {
    //       price: '30',
    //       time: 'Lifetime',
    //       shoppy_link:
    //         'https://hitmansservices.sellix.io/product/62b0f6803f1e1',
    //     },
    //   ],
    // },
    {
      _id: "26",
      name: "Permanent HWID Change",
      bestseller: true,
      product_image: "images/products/product_27.png",
      status_1: "active",
      status_2: "undetected",
      category: [
        { name: MODERNWARFARE },
        { name: COLDWAR },
        { name: BLACKOPS4 },
        { name: VANGUARD },
        { name: OTHER },
      ],
      starting_price: "75.00",
      images: [
        {
          link: "https://i.imgur.com/Mext4x9.gif",
        },
        {
          link: "https://i.imgur.com/w8ubW9m.gif",
        },
      ],
      subscriptions: [
        {
          price: "75",
          time: "ONE HWID UNBAN",
          shoppy_link:
            "https://hitmansservices.mysellix.io/product/6330c84185934",
        },
      ],
    },
    {
      _id: "29",
      name: "Private MW3 Cheat",
      product_image: "images/products/product_30.png",
      bestseller: true,
      status_1: "active",
      status_2: "undetected",
      category: [{ name: MODERNWARFARE3 }],
      starting_price: "60.00",
      images: [
        {
          link: "https://i.imgur.com/Xe7PVFN.gif",
        },
      ],
      subscriptions: [
        {
          price: "60.00",
          time: "Lifetime",
          shoppy_link:
            "https://hitmansservices.mysellix.io/product/65580cc5190bd",
        },
      ],
    },
    // {
    //   _id: "30",
    //   name: "MW3 UNLOCK ALL TOOL",
    //   product_image: "images/products/product_31.png",
    //   bestseller: true,
    //   status_1: "active",
    //   status_2: "undetected",
    //   category: [{ name: MODERNWARFARE3 }],
    //   starting_price: "35.00",
    //   images: [
    //     {
    //       link: "https://i.imgur.com/1gmykci.gif",
    //     },
    //     {
    //       link: "https://i.imgur.com/mNhK39B.gif",
    //     },
    //     {
    //       link: "https://i.imgur.com/gI2wUgQ.gif",
    //     },
    //   ],
    //   subscriptions: [
    //     {
    //       price: "35.00",
    //       time: "1 Month",
    //       shoppy_link:
    //         "https://hitmansservices.mysellix.io/product/6598806471f8f",
    //     },
    //   ],
    // },
    // {
    //   _id: '31',
    //   name: 'COD BLOCKER',
    //   product_image: 'images/products/product_32.png',
    //   bestseller: true,
    //   status_1: 'active',
    //   status_2: 'undetected',
    //   category: [
    //     { name: MODERNWARFARE3 },
    //     { name: WARZONE },
    //     { name: MODERNWARFARE2 },
    //   ],
    //   starting_price: '30.00',
    //   images: [
    //     {
    //       link: 'https://i.imgur.com/fvzVvhD.gif',
    //     },
    //   ],
    //   subscriptions: [
    //     {
    //       price: '30.00',
    //       time: '1 Month',
    //       shoppy_link:
    //         'https://hitmansservices.mysellix.io/product/63fed3f5c7952',
    //     },
    //   ],
    // },
    {
      _id: "32",
      name: "FORTNITE TOOL",
      product_image: "images/products/product_33.png",
      bestseller: true,
      status_1: "active",
      status_2: "undetected",
      category: [{ name: FORTNITE }],
      starting_price: "15.00",
      images: [
        {
          link: "https://cdn.sellix.io/storefront/59b6b24b7e96188454196ebf3c5f25b754bbdd7d/68747470733a2f2f692e696d6775722e636f6d2f626f416267596d2e676966",
        },
        {
          link: "https://cdn.sellix.io/storefront/c60be0cd519f9ed87a985ecf966566b2dc89c61b/68747470733a2f2f692e696d6775722e636f6d2f647234473039682e676966",
        },
      ],
      subscriptions: [
        {
          price: "15.00",
          time: "1 Month",
          shoppy_link:
            "https://hitmansservices.mysellix.io/product/641dbcb69ff75",
        },
      ],
    },
    {
      _id: "33",
      name: "Cold war cheat",
      product_image: "images/products/product_34.png",
      bestseller: true,
      status_1: "active",
      status_2: "undetected",
      category: [{ name: COLDWAR }],
      starting_price: "15.00",
      images: [
        {
          link: "https://i.imgur.com/1GBgrJp.gif",
        },
        {
          link: "https://i.imgur.com/pYlxP1v.gif",
        },
      ],
      subscriptions: [
        {
          price: "15",
          time: "1 Month",
          shoppy_link:
            "https://hitmansservices.mysellix.io/product/64cd4b9a4e6ea",
        },
        {
          price: "45",
          time: "Lifetime",
          shoppy_link:
            "https://hitmansservices.mysellix.io/product/64cd4c716b103",
        },
      ],
    },
    {
      _id: "34",
      name: "Cheat with spoofer & blocker + Unlock Tool",
      product_image: "images/products/product_35.png",
      bestseller: true,
      status_1: "active",
      status_2: "undetected",
      category: [{ name: MODERNWARFARE3 }],
      starting_price: "35.00",
      images: [
        {
          link: "https://i.imgur.com/ZAxoTcC.gif",
        },
        {
          link: "https://i.imgur.com/EJQ37cH.gif",
        },
      ],
      subscriptions: [
        {
          price: "35",
          time: "1 Month",
          shoppy_link:
            "https://hitmansservices.mysellix.io/product/6598806471f8f",
        },
      ],
    },
    {
      _id: "35",
      name: "Warzone 2 Accounts",
      product_image: "images/products/product_10.png",
      bestseller: true,
      status_1: "active",
      status_2: "undetected",
      category: [{ name: WARZONE }],
      starting_price: "2.00",
      images: [
        {
          link: "https://imagedelivery.net/A5gbiev6F8AaBvp6M146Kw/af882aae-bf66-466a-d491-f60d01770b00/productCard",
        },
      ],
      subscriptions: [
        {
          price: "2",
          time: "1 Account",
          shoppy_link:
            "https://hitmansservices.mysellix.io/product/62c3965b983ae",
        },
      ],
    },
    {
      _id: "36",
      name: "Project Movies",
      product_image: "images/products/product_7.png",
      bestseller: false,
      status_1: "active",
      status_2: "undetected",
      category: [{ name: OTHER }],
      starting_price: "10.00",
      images: [
        {
          link: "https://imagedelivery.net/A5gbiev6F8AaBvp6M146Kw/e3e1ce25-decb-45a3-b3c8-413915f8f700/productCard",
        },
      ],
      subscriptions: [
        {
          price: "10",
          time: "1 Month",
          shoppy_link:
            "https://hitmansservices.mysellix.io/product/62b10a06da035",
        },
        {
          price: "50",
          time: "1 Year",
          shoppy_link:
            "https://hitmansservices.mysellix.io/product/63b5bd8e1208a",
        },
      ],
    },
    {
      _id: "37",
      name: "Mastery Plan",
      product_image: "images/products/product_32.png",
      bestseller: false,
      status_1: "active",
      status_2: "undetected",
      category: [
        { name: MODERNWARFARE2 },
        { name: MODERNWARFARE3 },
        { name: COLDWAR },
        { name: BLACKOPS4 },
        { name: MODERNWARFARE },
        { name: WARZONE },
      ],
      starting_price: "150.00",
      images: [
        {
          link: "https://cdn.sellix.io/storefront/f5a207b28ee23c97bc4301cdc47026945500158e/68747470733a2f2f692e696d6775722e636f6d2f4649306f3965692e676966",
        },
      ],
      subscriptions: [
        {
          price: "150",
          time: "Lifetime",
          shoppy_link:
            "https://hitmansservices.mysellix.io/product/62b100536a588",
        },
      ],
    },
  ],
  categories: [
    "MW3",
    "MW2",
    "MW",
    "Warzone 2",
    "Cold War",
    "Vanguard",
    "Black Ops 4",
    "Fortnite",
    "Other",
  ],
};

export default data;
