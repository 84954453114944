import React from "react";
import styles from "./Navigation.module.scss";
import shoppyLogo from "../../../assets/images/shoppy.png";
import discordLogo from "../../../assets/images/discord.png";
import Hamburger from "../../features/Hamburger/Hamburger";
import { NavLink } from "react-router-dom";

class Navigation extends React.Component {
  render() {
    return (
      <>
        <Hamburger />
        <nav className={styles.container}>
          <ul className={styles.navbar_left}>
            <li>
              <NavLink
                exact
                to="/"
                style={({ isActive }) => ({
                  borderBottom: isActive ? "solid 2px #36abff" : null,
                  paddingBottom: isActive ? "10px" : null,
                })}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/products/"
                style={({ isActive }) => ({
                  borderBottom: isActive ? "solid 2px #36abff" : null,
                  paddingBottom: isActive ? "10px" : null,
                })}
              >
                My Services
              </NavLink>
            </li>

            <li>
              <a
                href="https://t.me/joinchat/ch_lEBF4GoY5NWM0"
                rel="noreferrer"
                target="_blank"
              >
                Telegram
              </a>
            </li>
            <li>
              <a
                href="/discord"
                rel="noreferrer"
                target="_blank"
              >
                Support
              </a>
            </li>
            <li>
              <a
                href="https://forum.hitmansservices.com/"
                rel="noreferrer"
                target="_blank"
              >
                Forum
              </a>
            </li>
            <li>
              <NavLink
                exact
                to="/faq/"
                style={({ isActive }) => ({
                  borderBottom: isActive ? "solid 2px #36abff" : null,
                  paddingBottom: isActive ? "10px" : null,
                })}
              >
                Faq
              </NavLink>
            </li>
          </ul>
          <ul className={styles.navbar_right}>
            <li>
              <div className={styles.button_blue}>
                <div className={styles.icon}>
                  <img src={shoppyLogo} alt="Shoppy Logo" />
                </div>
                <a
                  className={styles.button}
                  rel="noreferrer"
                  target="_blank"
                  href=" https://hitmansservices.mysellix.io/"
                >
                  GO TO SHOP
                </a>
              </div>
            </li>
            <li>
              <div className={styles.button_yellow}>
                <div className={styles.icon}>
                  <img src={discordLogo} alt="Discord Logo" />
                </div>
                <a
                  className={styles.button}
                  rel="noreferrer"
                  target="_blank"
                  href="discord"
                >
                  JOIN DISCORD
                </a>
              </div>
            </li>
          </ul>
        </nav>
      </>
    );
  }
}

export default Navigation;
