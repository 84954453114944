import React from "react";
import styles from "./Hamburger.module.scss";
import { NavLink } from "react-router-dom";

const Hamburger = () => (
  <nav id="nav">
    <input type="checkbox" id="hamburger" />
    <label className={styles.hamburger1} htmlFor="hamburger"></label>

    <ul className={styles.nav_links}>
      <li>
        <NavLink
          exact
          to="/"
        >
          Home
        </NavLink>
      </li>
      <li>
        <NavLink
          exact
          to="/products"
        >
          My Services
        </NavLink>
      </li>
      <li>
        <a
          href="https://t.me/joinchat/ch_lEBF4GoY5NWM0"
          rel="noreferrer"
          target="_blank"
        >
          Telegram
        </a>
      </li>
      <li>
        <a
          href="/discord"
          rel="noreferrer"
          target="_blank"
        >
          Support
        </a>
      </li>
      <li>
        <NavLink
          exact
          to="/faq"
        >
          Faq
        </NavLink>
      </li>
      <li>
        <a
          href="https://forum.hitmansservices.com/"
          rel="noreferrer"
          target="_blank"
        >
          Forum
        </a>
      </li>
      <li>
        <a
          rel="noreferrer"
          target="_blank"
          href="/discord"
        >
          Join Our Discord
        </a>
      </li>
      <li>
        <a
          rel="noreferrer"
          target="_blank"
          href=" https://hitmansservices.mysellix.io/"
        >
          Go To Shop
        </a>
      </li>
    </ul>
  </nav>
);

export default Hamburger;
